import {
  FormControl,
  FormLabel,
  Select
} from '@chakra-ui/react';

interface RenderPageSizeSelectorProps {
  setPageSize: (size: number) => void;
  callFurtherPages: (
    fileType: string,
    pageNo?: number,
    partnerName?: string,
    personaName?: string,
    size?: number
  ) => Promise<void>;
  selectedPartner: any;
  selectedPersona: any;
  setApprovedPageNo: (page: number) => void;
  setPendingPageNo: (page: number) => void;
  pageSize: number;
  setGroup: (group: string) => void;
  group: string;
  groups: string[];
}
export function renderPageSizeSelector({
  setPageSize, callFurtherPages, selectedPartner, selectedPersona, setApprovedPageNo, setPendingPageNo, pageSize, setGroup, group, groups,
}: RenderPageSizeSelectorProps) {
  return () => {
    return (
      <>
        <FormControl mb={4}>
          <>
            <FormLabel htmlFor="page-size-select" fontWeight="bold">
              Page Size
            </FormLabel>
            <Select
              title="page-size-select"
              aria-label="Page Size Selector"
              id="page-size-select"
              placeholder="Select page size"
              onChange={(e) => {
                const newSize = Number(e.target.value);
                setPageSize(newSize);
                callFurtherPages(
                  'approved',
                  1,
                  selectedPartner,
                  selectedPersona.name,
                  newSize
                );
                callFurtherPages(
                  'pending',
                  1,
                  selectedPartner,
                  selectedPersona.name,
                  newSize
                );
                setApprovedPageNo(1);
                setPendingPageNo(1);
              }}
              mb={4}
              value={pageSize}
            >
              {[25, 50, 75, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>
            <FormLabel htmlFor="group-select" fontWeight="bold">
              Select Group
            </FormLabel>
            <Select
              title="group-select"
              aria-label="Group Selector"
              id="group-select"
              placeholder="Select group"
              onChange={(e) => {
                const selectedGroup = e.target.value;
                setGroup(selectedGroup);
                callFurtherPages(
                  'approved',
                  1,
                  selectedPartner,
                  selectedPersona.name
                );
                callFurtherPages(
                  'pending',
                  1,
                  selectedPartner,
                  selectedPersona.name
                );
                setApprovedPageNo(1);
                setPendingPageNo(1);
              }}
              mb={4}
              value={group}
            >
              {groups.map((group) => (
                <option key={group} value={group}>
                  {group}
                </option>
              ))}
            </Select>
          </>
        </FormControl>
      </>
    );
  };
}
