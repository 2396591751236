import { ArrowBackIcon } from "@chakra-ui/icons";
import { Flex, Box, IconButton } from "@chakra-ui/react";
import { BsLink } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrentFileOpen } from "../../../../store/reducers/regChange";
import ReactMarkdown from "react-markdown";

interface DrillDownContentProps {
  regulatoryChangeData: any;
}

const DrillDownContent: React.FC<DrillDownContentProps> = ({
  regulatoryChangeData,
}) => {
  const [regulatoryChange, setRegulatoryChange] =
    useState<any>(regulatoryChangeData);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleLinkClick = (link: string) => {
    if (link) {
      window.open(link, "_blank");
    }
  };

  const handleGoBack = () => {
    dispatch(setCurrentFileOpen(false));
  };

  useEffect(() => {
    console.log("Regulatory Change Data: ", regulatoryChangeData);
    console.log("Regulatory Change: ", regulatoryChange);
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }
  return (
    <Flex w="100%" maxWidth="100%" direction="row" position="relative">
      {(regulatoryChange.change) && (
        <Box mb={4} pt={260}>
          <Box
            as="h1"
            p={6}
            mb={6}
            borderWidth="1px"
            borderRadius="10px"
            fontSize="xl"
            fontWeight="bold"
            display="flex"
            alignItems="center"
          >
            <IconButton
              icon={<ArrowBackIcon />}
              aria-label="Back"
              variant="outline"
              colorScheme="blue"
              onClick={handleGoBack}
              mr={2}
            />
            <Box
              whiteSpace="pre-wrap"
              wordBreak="break-word"
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth="100%"
            >
              {regulatoryChange?.display_name?.length > 62
                ? `${regulatoryChange.display_name.substring(0, 62)}...`
                : regulatoryChange.display_name}
            </Box>
          </Box>
          <Box
            as="h2"
            p={6}
            mb={6}
            borderWidth="1px"
            borderRadius="10px"
            fontSize="m"
            lineHeight="1.75"
          >
            <strong>Summary:</strong>
            <ReactMarkdown className="font-medium markdown-content">
              {regulatoryChange?.summary}
            </ReactMarkdown>
          </Box>
          <Box
            as="h2"
            p={6}
            mb={6}
            borderWidth="1px"
            borderRadius="10px"
            fontSize="m"
            lineHeight="1.75"
          >
            <div>
              <strong>Impact Level: </strong>{" "}
              {regulatoryChange?.impact_level || "N/A"}
            </div>
            <div>
              <strong>Impact Description:</strong>
              <div>{regulatoryChange?.impact_reason || "N/A"}</div>
            </div>
          </Box>
          <Box
            as="h2"
            p={6}
            mb={6}
            borderWidth="1px"
            borderRadius="10px"
            fontSize="m"
            lineHeight="1.75"
          >
            {regulatoryChange?.old_document_url && (
              <>
                <strong>New</strong>
              </>
            )}{" "}
            <strong>Document:</strong>
            <div>
              <button
                onClick={() =>
                  handleLinkClick(regulatoryChange.new_document_url)
                }
                style={{ display: "flex", alignItems: "center" }}
              >
                <span>{regulatoryChange?.document_name}</span>{" "}
                <BsLink style={{ marginLeft: "4px" }} />
              </button>
            </div>
            {regulatoryChange?.old_document_url && (
              <>
                <strong>Old Document:</strong>
                <div>
                  <button
                    onClick={() =>
                      handleLinkClick(regulatoryChange.old_document_url)
                    }
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span>{regulatoryChange?.document_name}</span>{" "}
                    <BsLink style={{ marginLeft: "4px" }} />
                  </button>
                </div>
              </>
            )}
          </Box>
        </Box>
      )}
    </Flex>
  );
};

export default DrillDownContent;
