import {
  DeleteIcon,
  EditIcon,
  InfoOutlineIcon
} from '@chakra-ui/icons';
import {
  Box, Flex, Text,
  Tooltip
} from '@chakra-ui/react';
import { IoMdImages } from 'react-icons/io';
import AdminPageSelect from '../../../components/pageSelect/adminPageSelect';

export function renderPendingFilesList(totalPendingCount: number, handleOnPageChangeApproved: (page: number) => void, handleOnPageChangePending: (page: number) => void, pendingPageNo: number, pendingTotalPages: number, view: string, pendingLoading: boolean, filteredPendingFiles: any, handleApprovalFileClick: (fileName: string, displayName: string, documentId: number) => void, handleEditFileName: (editedFile: any) => void, handleOpenDeleteAlert: (fileName: string) => void) {
  return <>
    <Text fontWeight="bold" mb={1} fontSize="lg">
      Total Pending Files: {totalPendingCount}
    </Text>
    <AdminPageSelect onPageChangeApproved={handleOnPageChangeApproved} onPageChangePending={handleOnPageChangePending} currentPagePending={pendingPageNo} totalPagesPending={pendingTotalPages} view={view} loading={pendingLoading} />
    {Object.keys(filteredPendingFiles).length ? (
      Object.keys(filteredPendingFiles).map(
        (group, groupIndex) => (
          <Box
            key={groupIndex}
            mb={4}
            className={pendingLoading ? 'pulse' : ''}
          >
            <Text fontWeight="bold" fontSize="md" mb={2}>
              {group}
            </Text>
            {filteredPendingFiles[group].map(
              (file: any, fileIndex: any) => (
                <Flex
                  key={fileIndex}
                  alignItems="center"
                  justifyContent="space-between"
                  mb={2}
                >
                  <Box
                    onClick={() => handleApprovalFileClick(
                      file.file_name,
                      file.display_name || file.file_name,
                      file.id
                    )}
                    cursor="pointer"
                    flex="1"
                  >
                    <Text>
                      {file.display_name || file.file_name}
                    </Text>
                  </Box>
                  <Flex alignItems="center">
                    {file.last_modified_time && (
                      <Text
                        fontSize="sm"
                        color="gray.500"
                        mr={4}
                      >
                        Uploaded:{' '}
                        {new Date(
                          file.last_modified_time
                        ).toLocaleString()}
                      </Text>
                    )}

                    <Tooltip
                      label="Image Available"
                      aria-label="Image tooltip"
                    >
                      <Box
                        as={file.has_image ? IoMdImages : Box} // Use Box as a placeholder when no image
                        color={file.has_image
                          ? 'white.500'
                          : 'transparent'} // Make icon transparent if no image
                        boxSize={{ base: 4, md: 5 }}
                        mr={2}
                        title={file.has_image
                          ? 'Has Image'
                          : 'No Image'} />
                    </Tooltip>
                    <Tooltip
                      label="Edit Name"
                      aria-label="Edit tooltip"
                    >
                      <Box
                        as={EditIcon}
                        ml={2}
                        color="white"
                        cursor="pointer"
                        onClick={() => handleEditFileName(file)}
                        boxSize={8}
                        borderRadius="50%"
                        p={2}
                        _hover={{ bg: 'blue.600' }} />
                    </Tooltip>
                    <Tooltip
                      label="Show Details"
                      aria-label="Show Details tooltip"
                    >
                      <Box
                        as={InfoOutlineIcon}
                        color="white"
                        cursor="pointer"
                        onClick={() => handleApprovalFileClick(
                          file.file_name,
                          file.display_name ||
                          file.file_name,
                          file.id
                        )}
                        boxSize={8}
                        ml={2}
                        borderRadius="50%"
                        p={2}
                        _hover={{ bg: 'blue.600' }} />
                    </Tooltip>
                    <Tooltip
                      label="Delete"
                      aria-label="Delete tooltip"
                      hasArrow
                      bg="red.500"
                    >
                      <Box
                        as={DeleteIcon}
                        color="white"
                        cursor="pointer"
                        onClick={() => handleOpenDeleteAlert(
                          file.file_name
                        )}
                        ml={2}
                        boxSize={8}
                        borderRadius="50%"
                        p={2}
                        _hover={{ bg: 'red.600' }} />
                    </Tooltip>
                  </Flex>
                </Flex>
              )
            )}
          </Box>
        )
      )
    ) : (
      <Text>No pending files available</Text>
    )}
  </>;
}
