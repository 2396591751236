import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Box, FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react';

export function searchFileInput(search: string, setSearch: React.Dispatch<React.SetStateAction<string>>, handleSearch: (event: any) => void, inputTextColor: string) {
  return <Box px={0} py={2}>
    <FormControl mb={4}>
      <FormLabel htmlFor="search" fontWeight="bold">
        Search Files
      </FormLabel>
      <InputGroup>
        <Input
          id="search"
          placeholder="Search by file name"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSearch(e);
        }
          }}
          color={inputTextColor} />
        <>
        <InputRightElement width="4.5rem">
          <IconButton
            size="sm"
            aria-label="Search"
            icon={<SearchIcon />}
            onClick={() => handleSearch({ target: { value: search } })}
          />
          <IconButton
            size="sm"
            aria-label="Clear search"
            icon={<CloseIcon />}
            onClick={() => {
              setSearch('');
              handleSearch({ target: { value: '' } });
            }}
            ml={2}
            mr={1}
          />
        </InputRightElement>
            </>
      </InputGroup>
    </FormControl>
  </Box>;
}
