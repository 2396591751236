import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react';
import { RenderFileListTabsProps } from './FilelIst';

export function renderFileListTabs({ view, setView }: RenderFileListTabsProps) {
  return (
    <><Tabs
      index={view === 'approved' ? 0 : 1}
      onChange={(index) => setView(index === 0 ? 'approved' : 'pending')}
    >
      <TabList px="25">
        <Tab>Approved</Tab>
        <Tab>Pending Approval</Tab>
      </TabList><TabPanels>
        <TabPanel>{/* Content for Approved Queue */}</TabPanel>
        <TabPanel>{/* Content for Pending Approval */}</TabPanel>
      </TabPanels>
    </Tabs></>
  );
}
