import {
  FormControl,
  FormLabel,
  Select
} from '@chakra-ui/react';

export function createPartnerAndPersonaSelectors(handlePartnerChange: (e: React.ChangeEvent<HTMLSelectElement>) => Promise<void>, selectedPartner: any, partners: any[], handlePersonaChange: (e: React.ChangeEvent<HTMLSelectElement>) => void, selectedPersona: any, loading: boolean, personas: any[]) {
  const partnerSelect = <FormControl mb={4}>
    <FormLabel htmlFor="partner-select" fontWeight="bold">
      Select Partner
    </FormLabel>
    <Select
      title='partner-select'
      id="partner-select"
      placeholder="Select partner"
      onChange={handlePartnerChange}
      mb={4}
      value={selectedPartner || ''}
    >
      {partners.map((partner) => (
        <option key={partner.id} value={partner.id}>
          {partner.display_name || partner.name}
        </option>
      ))}
    </Select>
  </FormControl>;

  const personaSelect = <FormControl mb={4}>
    <FormLabel htmlFor="persona-select" fontWeight="bold">
      Select Persona
    </FormLabel>
    <Select
      title='persona-select'
      id="persona-select"
      placeholder="Select persona"
      onChange={handlePersonaChange}
      mb={4}
      value={selectedPersona?.id || ''}
      isDisabled={loading}
    >
      {personas?.map((persona) => (
        <option key={persona.id} value={persona.id}>
          {persona.display_name}
        </option>
      ))}
    </Select>
  </FormControl>;
  return { partnerSelect, personaSelect };
}
