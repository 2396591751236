import {
  Box,
  Flex,
  Icon,
  Progress,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import CustomCard from "components/card/Card";
import PremiumModal from "components/modals/PremiumModal";
import React, { useEffect, useRef, useState } from "react";
import { IconType } from "react-icons";
import { FaFileAlt, FaFilePdf, FaFileWord } from "react-icons/fa";
import { MdAutoAwesome, MdBolt, MdPerson, MdSunny } from "react-icons/md";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import "./chat.css";
import Tile from "./Tile";
import { useMediaQuery } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import DrillDownContent from "../solutions/regulatory/drillDownContent";
import { set } from "date-fns";

// Custom link renderer to ensure links open in a new tab
const customRenderers = {
  a: ({
    href,
    children,
    ...props
  }: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{ cursor: "pointer" }}
      {...props}
    >
      {children}
    </a>
  ),
};

const Messages: React.FC<any> = ({
  handleSendMessage,
  tabValue,
  selectedFiles,
  sendButtonClicked,
  progressArray,
  flexRef,
  attachedQuestion,
  regulatoryChangeData,
}) => {
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.200");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("navy.700", "white");

  let SelectedIcon: IconType;
  if (tabValue === 1) {
    SelectedIcon = MdAutoAwesome;
  } else if (tabValue === 2) {
    SelectedIcon = MdBolt;
  } else if (tabValue === 3) {
    SelectedIcon = MdSunny;
  } else {
    SelectedIcon = MdPerson;
  }

  const inputColor = useColorModeValue("navy.700", "white");

  const divRef = useRef<HTMLDivElement>(null);
  const messages = useSelector((state: RootState) => state.messages);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const location = useLocation();

  const [isChat, setIsChat] = useState(false);

  const [regChangeSkipScroll, setRegChangeSkipScroll] = useState(true);

  useEffect(() => {
    console.log(regulatoryChangeData);
    if (location.pathname === "/compliance/chat") {
      setIsChat(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    
    const scrollToBottom = () => {
      if (shouldScrollToBottom) {
        divRef.current.scrollTop = divRef.current.scrollHeight;
      }
    };
    if (!regChangeSkipScroll || isChat) {
      scrollToBottom();
    }
    setRegChangeSkipScroll(false);
  }, [messages, shouldScrollToBottom, sendButtonClicked]);

  useEffect(() => {
    const handleScroll = () => {
      if (divRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = divRef.current;
        if (scrollTop < scrollHeight - clientHeight) {
          setShouldScrollToBottom(false);
        }
      }
    };

    const divElement = divRef.current;
    if (divElement) {
      divElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (divElement) {
        divElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <Flex
      ref={divRef}
      w="100%"
      h="80%"
      mt="10px"
      overflowY="auto"
      flexDirection="column"
      p="3"
      sx={{
        "&::-webkit-scrollbar": {
          width: isChat ? "8px" : "0px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: isChat ? "#7551FF" : "transparent",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: isChat ? "#555" : "transparent",
        },
      }}
    >
      {tabValue === 3 && <PremiumModal />}
      {/* {messages.map((item: any, index: any) => { */}

      {!isChat && regulatoryChangeData && (
        <DrillDownContent regulatoryChangeData={regulatoryChangeData} />
      )}

      {messages.map((item: any, index: any) => {
        if (item.from === "me") {
          const startTag = "<FILETAGSTART>";
          const endTag = "</FILETAGEND>";

          // Extract the HTML content inside FILETAGSTART and FILETAGEND
          let htmlContent = null;
          let remainingText = item.text;

          if (item.text.includes(startTag) && item.text.includes(endTag)) {
            const startIndex = item.text.indexOf(startTag) + startTag.length;
            const endIndex = item.text.indexOf(endTag);
            htmlContent = item.text.substring(startIndex, endIndex).trim();

            // Extract remaining text (outside of tags)
            const beforeTagText = item.text
              .substring(0, item.text.indexOf(startTag))
              .trim();
            const afterTagText = item.text
              .substring(endIndex + endTag.length)
              .trim();

            remainingText = `${beforeTagText} ${afterTagText}`.trim();
          }

          return (
            <Flex
              key={index}
              w="100%"
              align="top"
              my="10px"
              display={isMobile ? "unset" : "flex"}
            >
              <Flex
                width={isMobile ? "100%" : ""}
                borderRadius="full"
                justify="center"
                align="center"
                bg="transparent"
                border="1px solid"
                borderColor={borderColor}
                me="20px"
                h="40px"
                minH="40px"
                minW="40px"
              >
                <Icon
                  as={MdPerson}
                  width="20px"
                  height="20px"
                  color={brandColor}
                />
              </Flex>
              <Flex flexDirection="column" flex="1">
                {htmlContent && (
                  // Render extracted HTML content with dangerouslySetInnerHTML
                  <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                )}
                {remainingText && (
                  <Text
                    color={textColor}
                    fontWeight="600"
                    fontSize={{ base: "sm", md: "md" }}
                    lineHeight={{ base: "24px", md: "26px" }}
                    mt={htmlContent ? "10px" : "0"}
                  >
                    {remainingText}
                  </Text>
                )}
              </Flex>
            </Flex>
          );
        } else {
          return (
            <Flex key={index} w="100%" display={isMobile ? "unset" : "flex"}>
              <Flex
                width={isMobile ? "100%" : ""}
                borderRadius="full"
                justify="center"
                align="center"
                bg="linear-gradient(15.46deg, rgb(54, 82, 186) 26.3%, rgb(6, 128, 255) 86.4%)"
                me="20px"
                h="40px"
                minH="40px"
                minW="40px"
              >
                <Icon
                  as={SelectedIcon}
                  width="20px"
                  height="20px"
                  color="white"
                />
              </Flex>
              <CustomCard
                px="22px !important"
                pl="22px !important"
                color={textColor}
                minH="80px"
                fontSize={{ base: "sm", md: "md" }}
                lineHeight={{ base: "24px", md: "26px" }}
                fontWeight="500"
              >
                {item.text.trim() === "" ? (
                  <Flex alignItems="center">
                    <Spinner color="navy.100" />
                    <Text
                      fontSize="md"
                      fontWeight="bold"
                      color={textColor}
                      textAlign="left"
                      ml="10px"
                      mr="10px"
                    >
                      Thinking...
                    </Text>
                  </Flex>
                ) : (
                  <ReactMarkdown
                    className="font-medium markdown-content"
                    components={customRenderers}
                  >
                    {item.text}
                  </ReactMarkdown>
                )}
              </CustomCard>
            </Flex>
          );
        }
      })}

      {sendButtonClicked && selectedFiles.length > 0 && (
        <Flex w="100%" align="top" my="10px">
          <Flex
            borderRadius="full"
            justify="center"
            align="center"
            bg="transparent"
            border="1px solid"
            borderColor={borderColor}
            me="20px"
            h="40px"
            minH="40px"
            minW="40px"
          >
            <Icon as={MdPerson} width="20px" height="20px" color={brandColor} />
          </Flex>
          <Flex flexDirection="column" flex="1">
            {/* Display Selected Files=================================== */}
            <div ref={flexRef}>
              <Flex p={{ base: "2px", md: "5px" }} gap="5px" wrap="wrap">
                {selectedFiles.map((file: any, index: any) => (
                  <Flex
                    key={index}
                    justifyContent="space-between"
                    alignItems="center"
                    mb="4px"
                    width={{ base: "180px", md: "220px" }}
                    p="10px"
                    border="1px solid"
                    borderColor="gray.500"
                    borderRadius="md"
                    position="relative"
                    flexDirection="column" // Make space for progress bar
                  >
                    <Box display="flex" alignItems="center">
                      <Box mr="8px">
                        {file.type === "application/pdf" && (
                          <Icon as={FaFilePdf} color="white" w={6} h={6} />
                        )}
                        {(file.type === "application/msword" ||
                          file.type ===
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && (
                          <Icon as={FaFileWord} color="white" w={6} h={6} />
                        )}
                        {file.type === "text/plain" && (
                          <Icon as={FaFileAlt} color="white" w={6} h={6} />
                        )}
                        {file.type === "text/html" && (
                          <Icon as={FaFileAlt} color="white" w={6} h={6} />
                        )}
                      </Box>
                      <Box>
                        <Text
                          fontSize="sm"
                          color={inputColor}
                          isTruncated
                          maxW={{ base: "100px", md: "130px" }}
                        >
                          {file.name}
                        </Text>
                        <Text fontSize="sm" color={inputColor}>
                          {file.type === "application/pdf"
                            ? "PDF"
                            : file.type === "application/msword"
                            ? "DOC"
                            : file.type ===
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            ? "DOCX"
                            : file.type === "text/plain"
                            ? "TXT"
                            : file.type.toUpperCase()}
                        </Text>
                      </Box>
                    </Box>
                    {/* Progress Bar */}
                    <Progress
                      value={progressArray[index]} // Dynamic progress for each file
                      size="sm"
                      colorScheme="white"
                      mt="8px"
                      width="100%"
                      height="4px"
                    />
                  </Flex>
                ))}
              </Flex>
            </div>
            {/* Display Selected Files=================================== */}
            {attachedQuestion && (
              <Text
                fontSize="md"
                fontWeight="bold"
                color={textColor}
                textAlign="left"
                ml="10px"
                mr="10px"
              >
                {attachedQuestion}
              </Text>
            )}
          </Flex>
        </Flex>
      )}

      {isChat &&
        !sendButtonClicked &&
        messages.length === 0 &&
        profile?.userId && (
          <Flex justify="center" align="center" mt={4} height="100vh">
            {tabValue === 1 && (
              <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={10}>
                <Tile
                  title="How can my bank prepare for section 1071?"
                  onClick={() =>
                    handleSendMessage(
                      "How can my bank prepare for section 1071?"
                    )
                  }
                />
                <Tile
                  title="What are the test steps in a redlining risk assessment?"
                  onClick={() =>
                    handleSendMessage(
                      "What are the test steps in a redlining risk assessment?"
                    )
                  }
                />
                <Tile
                  title="What is the ‘Right to Rescind’ in TILA and when does it apply?"
                  onClick={() =>
                    handleSendMessage(
                      "What is the ‘Right to Rescind’ in TILA and when does it apply?"
                    )
                  }
                />
              </SimpleGrid>
            )}
            {tabValue === 2 && (
              <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={10}>
                <Tile
                  title="Can you write me a set of 5 questions for a self quiz on the Indiana Consumer Protection regulation?"
                  onClick={() =>
                    handleSendMessage(
                      "Can you write me a set of 5 questions for a self quiz on the Indiana Consumer Protection regulation?"
                    )
                  }
                />
                <Tile
                  title="Can you tell me if I am able to share private information about a client with 3rd party vendors in California?"
                  onClick={() =>
                    handleSendMessage(
                      "Can you tell me if I am able to share private information about a client with 3rd party vendors in California?"
                    )
                  }
                />
                <Tile
                  title="What are the requirements for a non-banking entity to operate automated teller machines (ATMs) in New York?"
                  onClick={() =>
                    handleSendMessage(
                      "What are the requirements for a non-banking entity to operate automated teller machines (ATMs) in New York?"
                    )
                  }
                />
              </SimpleGrid>
            )}
          </Flex>
        )}
    </Flex>
  );
};

export default Messages;
