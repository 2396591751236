import { AttachmentIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useColorModeValue,
  Textarea,
  Input,
} from '@chakra-ui/react';
import useAuth from 'hooks/auth';
import React, { useRef, useEffect, useState } from 'react';
import { FaFileAlt, FaFileWord, FaHtml5 } from 'react-icons/fa';
import { FaFilePdf } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { setInputMessage } from 'store/reducers/inputMessage';
import { RootState } from 'store/store';
import { useMediaQuery } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

const ChatFooter: React.FC<any> = ({
  handleSendMessage,
  loading,
  handleRemoveFile,
  handleFileChange,
  selectedFiles,
  setSendButtonClicked,
  sendButtonClicked,
}) => {
  const location = useLocation();
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.200');
  const inputColor = useColorModeValue('navy.700', 'white');
  const { login } = useAuth();
  const handleLogIn = () => {
    if (!profile?.idToken || !profile?.userId) {
      login();
      return;
    }
  };
  const textColor = useColorModeValue('navy.700', 'white');
  const placeholderColor = useColorModeValue(
    { color: 'gray.500' },
    { color: 'whiteAlpha.600' }
  );
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const inputMessage = useSelector((state: RootState) => state.inputMessage);
  const dispatch = useDispatch();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isChat, setIsChat] = useState(false);

  let placeholderMsg = '';
  if (!profile?.idToken) {
    placeholderMsg = 'Login to ask your first question.';
  } else {
    if (isChat) {
      placeholderMsg = 'Type your message here...';
    } else {
      placeholderMsg = 'Ask a question about this change.';
    }
    
  }

  useEffect(() => {
    if (location.pathname === '/compliance/chat') {
      setIsChat(true);
    }
  }, [location.pathname]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight/1.2}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputMessage]);

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFileChange(event);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <Flex w="100%" mt="5" align="flex-end" display={isMobile ? "unset" : "flex"}>
      <Flex
        w="100%"
        minH="40px"
        maxH="200px"
        flexDirection="column"
        position="relative"
        border="1px solid"
        borderColor={borderColor}
        borderRadius="45px"
        pt="5px"
        pb="5px"
        px="20px"
        me={'10px'}
        overflowY="auto"
        _focusWithin={{
          borderColor: 'gray.400',
          boxShadow: '0 0 0 2px blue.500',
        }}
      >
        {isChat && !sendButtonClicked && selectedFiles.length > 0 && (
          <Flex mb="8px" p={{ base: '2px', md: '5px' }} gap="5px" wrap="wrap">
            {selectedFiles.map((file: any, index: any) => (
              <Flex
                key={index}
                justifyContent="space-between"
                alignItems="center"
                mb="4px"
                width={{ base: '180px', md: '220px' }}
                p="10px"
                border="1px solid"
                borderColor="gray.500"
                borderRadius="md"
                position="relative"
              >
                <Box display="flex" alignItems="center">
                  <Box mr="8px">
                    {file.type === 'application/pdf' && (
                      <Icon as={FaFilePdf} color="white" w={6} h={6} />
                    )}
                    {(file.type === 'application/msword' ||
                      file.type ===
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document') && (
                      <Icon as={FaFileWord} color="white" w={6} h={6} />
                    )}
                    {file.type === 'text/plain' && (
                      <Icon as={FaFileAlt} color="white" w={6} h={6} />
                    )}
                    {file.type === 'text/html' && (
                      <Icon as={FaHtml5} color="white" w={6} h={6} />
                    )}
                  </Box>
                  <Box>
                    <Text
                      fontSize="sm"
                      color={inputColor}
                      isTruncated
                      maxW={{ base: '100px', md: '130px' }}
                    >
                      {file.name}
                    </Text>
                    <Text fontSize="sm" color={inputColor}>
                      {file.type === 'application/pdf'
                        ? 'PDF'
                        : file.type === 'application/msword'
                        ? 'DOC'
                        : file.type ===
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        ? 'DOCX'
                        : file.type === 'text/plain'
                        ? 'TXT'
                        : file.type.toUpperCase()}
                    </Text>
                  </Box>
                </Box>
                <IconButton
                  size="xs"
                  aria-label="Remove file"
                  icon={<CloseIcon />}
                  onClick={() => handleRemoveFile(file.name)}
                  position="absolute"
                  top="2px"
                  right="2px"
                />
              </Flex>
            ))}
          </Flex>
        )}

        <Flex position="relative" pe="10px" me="10px">
          <Input
            type="file"
            accept=".pdf, .doc, .docx, .txt"
            display="none"
            id="pdf-upload"
            ref={fileInputRef}
            onChange={handleFileInputChange}
            multiple
          />

          <Textarea
            ref={textareaRef}
            pe={{ base: '10px', md: '30px' }}
            ps={{ base: '25px', md: '30px' }}
            value={inputMessage}
            minH="40px"
            maxH="200px"
            h="unset"
            resize="none"
            border="none"
            fontSize="sm"
            fontWeight="500"
            _focus={{ outline: 'none' }}
            color={inputColor}
            _placeholder={placeholderColor}
            placeholder={placeholderMsg}
            onChange={(e) => {
              dispatch(setInputMessage(e.target.value));
              adjustTextareaHeight();
            }}
            onClick={() => {
              if (!profile?.idToken) {
                handleLogIn();
              }
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !loading) {
                dispatch(setInputMessage(''));
                handleSendMessage();
                setSendButtonClicked(true);
              }
            }}
            className="custom-scrollbar"
          />
          {isChat && 
            <Tooltip label="Attach PDF file" aria-label="Attach PDF file">
              <IconButton
                as="label"
                htmlFor="pdf-upload"
                icon={<AttachmentIcon />}
                variant="unstyled"
                color={textColor}
                h="20px"
                position="absolute"
                top="50%"
                left={{ base: '2px', md: '5px' }}
                transform={{ base: 'translateY(-70%)', lg: 'translateY(-60%)' }}
                cursor="pointer"
                aria-label="Attach file"
              />
            </Tooltip>
          }
        </Flex>
      </Flex>

      <Button
        disabled={loading}
        variant="primary"
        py="20px"
        px="16px"
        mt={isMobile ? "5px" : ""}
        fontSize="sm"
        borderRadius="45px"
        ms="auto"
        w={isMobile ? "100%" : { base: '160px', md: '210px' }}
        h="55px"
        alignSelf="flex-start"
        _hover={{
          boxShadow: '0px 21px 27px -10px rgba(96, 60, 255, 0.48) !important',
          bg: 'linear-gradient(15.46deg, rgb(54, 82, 186) 26.3%, rgb(6, 128, 255) 86.4%) !important',
          _disabled: {
            bg: 'linear-gradient(15.46deg, rgb(54, 82, 186) 26.3%, rgb(6, 128, 255) 86.4%)',
          },
        }}
        onClick={() => {
          handleSendMessage();
          if (selectedFiles.length > 0) {
            setSendButtonClicked(true);
          }
        }}
      >
        {loading ? 'Sending...' : 'Send'}
      </Button>
    </Flex>
  );
};

export default ChatFooter;