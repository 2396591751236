import React from 'react';
import { Alert, AlertIcon, AlertTitle, AlertDescription, Button } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';

const AccessAlert: React.FC = () => {
    return (
        <Flex justifyContent="center" alignItems="center" minH="200px">
            <Alert
              status="error"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
              maxW="400px"
              borderRadius="lg"
              boxShadow="lg"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={2} fontSize="lg">
                Access Denied
              </AlertTitle>
              <AlertDescription maxWidth="sm" mb={4}>
                You do not have permission to access this page.
              </AlertDescription>
              <Button
                colorScheme="blue"
                onClick={() => (window.location.href = '/')}
              >
                Go to Home
              </Button>
            </Alert>
          </Flex>
    );
};

export default AccessAlert;