import axios from "axios";

export const getApprovedFilesPaginated = async (
    token: string, 
    partnerName: string, 
    persona: string, 
    page: number, 
    pageSize: number, 
    group: string,
    search: string,
) => {
    const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/get_approved_files?partner=${partnerName}&persona=${persona}&page=${page}&limit=${pageSize}${group !== 'all' ? `&group=${group}` : ''}${search !== '' ? `&search=${search}` : ''}`,
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
            },
        },
    );
    return response.data;
}

export const getPendingFilesPaginated = async (
    token: string, 
    partnerName: string, 
    persona: string, 
    page: number, 
    pageSize: number, 
    group: string,
    search: string
) => {
    const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/get_pending_files?partner=${partnerName}&persona=${persona}&page=${page}&limit=${pageSize}${group !== 'all' ? `&group=${group}` : ''}${search !== '' ? `&search=${search}` : ''}`,
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
            },
        }
    );
    return response.data;
}
